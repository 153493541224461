const mainUrl = "";
const apiUrl = `${mainUrl}/api`;
const twoFactorUrl = `${apiUrl}/TwoFactor`;

const Urls = {
    Main: mainUrl,
    Api: apiUrl,
    CurrentDate: `${apiUrl}/CurrentDateTime`,
    GetDocument: `${apiUrl}/Document`,
    Telemetry: `${apiUrl}/Telemetry`,
    GetQuestions: `${twoFactorUrl}/GetQuestions`,
    VerifyAnswer: `${twoFactorUrl}/Verify`,
    SendToken: `${twoFactorUrl}/SendToken`,
    VerifyToken: `${twoFactorUrl}/VerifyToken`
};

const TermsAndConditions = {
    title: `Terms and Conditions`,
    content:
        `AerosExpress™ Terms of Use
Throughout this document, “you” and “your” refer to the named insured shown on the accepted application for insurance and “CRS” refers to Crop Risk Services, Inc., Agrinational Insurance Company, Inc., and their subsidiaries and affiliates.

By using AerosExpress™ you agree to the following terms and conditions. These terms and conditions of usage (“Terms of Use”) apply to AerosExpress™ and all associated, applications, software, and sites accessible from and or connected to www.croprisksesrvices.com by Crop Risk Services. (“CRS”). AerosExpress™ is the property of Crop Risk Services.

Accounts, Passwords, Security
AerosExpress™ and associated sites may require you to create a user account. You are solely and entirely responsible for maintaining the confidentiality of your account, including your password, and for any and all activity that occurs under your account, including activity that is a result of your failure to keep the account information secure and confidential. You agree to notify CRS immediately of any unauthorized use of your account, or any other breach of data security. You may not use anyone else’s AerosExpress™ login credentials, password or account at any time without the express written permission and consent of the holder of that password or account. CRS cannot and will not be liable for any damage or loss arising from your failure to comply with these obligations.

Authorized User
By creating an account and using AerosExpress™ you certify you are the person authorized to view, download, edit, and submit data change requests and certifications for the data presented to you. This includes but is not limited to your insurance policy data, tax information, and policyholder information. You agree to notify CRS immediately if any unauthorized data is presented or if you are no longer authorized to view, download, edit, or submit data change requests and certifications for any portion of the data presented to you. You may not attempt to gain unauthorized access to any portion of AerosExpress™ or any of the services or sites connected to it. You may not impersonate, or represent any individual or entity other than those you are explicitly authorized to.

Certification
If you use AerosExpress™ to submit data or provide certain information under the Federal crop insurance program, you acknowledge and agree to the following Certification Statement:

I certify that to the best of my knowledge and belief all of the information on this form is correct. I also understand that failure to report completely and accurately may result in sanctions under my policy, including but not limited to voidance of the policy, and in criminal or civil penalties (18 U.S.C. §1006 and §1014; 7 U.S.C. §1506; 31 U.S.C. §3729, §3730 and any other applicable federal statutes).
Content
AerosExpress™, all text, graphics, trademarks, logos, artwork, user interfaces, visual interfaces, and computer code are the sole property of CRS and are controlled or licensed by CRS and are protected by copyright, patent, trademark, and intellectual property rights. You agree you will not attempt to reverse engineer, probe, scan, or test vulnerability of AerosExpress™ and the sites connected to it. You agree to not utilize any device, software, or routine that will interfere or attempt to disrupt the proper functionality of AerosExpress™ and its connected sites.

Consent to Conduct Business Electronically
By creating an account and using AerosExpress™ you consent to conduct business with Crop Risk Services on a full or partial basis through the AerosExpress™ platform. This consent shall continue until written notification is received by CRS Crop Risk Services specifically revoking such consent.

Nondiscrimination Statement
CRS is an equal opportunity provider. The U.S. Department of Agriculture (USDA) prohibits discrimination against its customers, employees, and applicants for employment on the bases of race, color, national origin, age, disability, sex, gender identity, religion, reprisal, and where applicable, political beliefs, marital status, familial or parental status, sexual orientation, or all or part of an individual"s income is derived from any public assistance program, or protected genetic information in employment or in any program or activity conducted or funded by the Department. (Not all prohibited bases will apply to all programs and/or employment activities).

Privacy
Crop Risk Services, Inc.’s privacy policy located at https://www.cropriskservices.com/Main/PrivacyAct applies to this application and all associated sites connected to www.cropriskservices.com. As it pertains to the Federal crop insurance program, you also agree to the Risk Management Agency (“RMA”) Collection of Information and Privacy Act Statement, below.

RMA Collection of Information and Privacy Act Statement
The following statements are made in accordance with the Privacy Act of 1974 (5 U.S.C. 552a): The Risk Management Agency (RMA) is authorized by the Federal Crop Insurance Act (7 U.S.C. 1501-1524) or other Acts, and the regulations promulgated thereunder, to solicit the information requested on documents established by RMA or by approved insurance providers (AIPs) that have been approved by the Federal Crop Insurance Corporation (FCIC) to deliver Federal crop insurance. The information is necessary for AIPs and RMA to operate the Federal crop insurance program, determine program eligibility, conduct statistical analysis, and ensure program integrity. Information provided herein may be furnished to other Federal, State, or local agencies, as required or permitted by law, law enforcement agencies, courts or adjudicative bodies, foreign agencies, magistrate, administrative tribunal, AIPs contractors and cooperators, Comprehensive Information Management System (CIMS), congressional offices, or entities under contract with RMA. For insurance agents, certain information may also be disclosed to the public to assist interested individuals in locating agents in a particular area. Disclosure of the information requested is voluntary. However, failure to correctly report the requested information may result in the rejection of this document by the AIP or RMA in accordance with the Standard Reinsurance Agreement between the AIP and FCIC, Federal regulations, or RMA-approved procedures and the denial of program eligibility or benefits derived therefrom. Also, failure to provide true and correct information may result in civil suit or criminal prosecution and the assessment of penalties or pursuit of other remedies.

Other Terms and Conditions
CRS may make changes to any services offered by AerosExpress™ and the sites connected to it at any time without notice. CRS reserves the right to modify, add, or remove portions of these Terms of Use at any time.`,
    rejectNoticeTitle: "Reject Notice",
    rejectNoticeContent:
        `
In order to use this software application you should accept all terms and conditions.

If you don't agree please contact your agent to use the regular signature process.
`,
    acceptButtonLabel: "Accept",
    rejectButtonLabel: "Decline",
    closeRejectNoticeButtonLabel: "Close"
};

const GenericErrorDialog = {
    title: "Error",
    content: "We are unable to validate your information. Please try again or contact our System Support Team at 1-877-551-0410.",
    acceptButtonLabel: "Accept"
};

const SubmitConfirmationDialog = {
    title: "Do you want to submit the Document?",
    content: `
If you click on "Accept" the document, will be sent as is.
If you click on "Cancel" you can go back and change your Signature.`,
    acceptButtonLabel: "Accept",
    rejectButtonLabel: "Cancel",
    source: "SignaturePanel.SubmitConfirmationDialog"
};

const RejectConfirmationDialog = {
    title: "Do you want to Reject Signing the Document?",
    content: `
If you click on "Reject" the document, will be rejected and other signers will be notified that the document was rejected.
If you click on "Cancel" you can go back and apply your Signature.`,
    acceptButtonLabel: "Reject",
    rejectButtonLabel: "Cancel",
    source: "SignaturePanel.RejectConfirmationDialog"
};

const SubmitSuccessDialog = {
    title: "Thank You!",
    content: `
Your signed document has been sent successfully.

You may save or print a copy of the signed document for your records.

Close this browser window to exit E-Sign.`,
    acceptButtonLabel: "Done"
};

const RejectionSuccessDialog = {
    title: "Notice",
    content: "Your rejection has been sent successfully.",
    acceptButtonLabel: "Accept"
};

const InvalidUrlNotice = {
    title: "Invalid URL",
    content: "The URL you are using is not correct."
};

const SecurityQuestions = {
    title: "Login",
    content: "Please select a question to answer.",
    questionsLabel: "Once you selected a question please write the corresponding answer.",
    verifyButtonLabel: "Verify answer"
};

const SecurityQuestionWrongAnswer = {
    title: "Warning",
    content: "The answer for the security question is not correct. Please try again.",
    acceptButtonLabel: "Accept"
};

const SecurityToken = {
    title: "Login:",
    content: "A security token has been sent to your phone.",
    securityTokenComment: "Please write it below to verify. Do not include hyphens.",
    inputTextLabel: "Capture token here",
    verifyButtonLabel: "Verify"
};

const VerificationTokenWrongAnswer = {
    title: "Warning",
    content: "The security token provided is not correct. Please try again.",
    acceptButtonLabel: "Accept"
};

const PreviouslySubmitted =
{
    title: "Previously Signed",
    content: "This document has already been signed. If this is in error please contact our System Support Team at 1-877-551-0410."
}

const SignatureRejected =
{
    title: "Rejected Document",
    content: "This document was previously rejected by a signer. If this is in error please contact our System Support Team at 1-877-551-0410."
}

const Canceled =
{
    title: "Canceled",
    content: "This document request was canceled. If this is in error please contact our System Support Team at 1-877-551-0410."
}

const Expired =
{
    title: "Expired",
    content: "This document request has expired. Documents expire after 7 days. If this is in error please contact our System Support Team at 1-877-551-0410."
}

export {
    TermsAndConditions,
    Urls,
    SubmitConfirmationDialog,
    SubmitSuccessDialog,
    GenericErrorDialog,
    InvalidUrlNotice,
    SecurityQuestions,
    SecurityQuestionWrongAnswer,
    SecurityToken,
    VerificationTokenWrongAnswer,
    PreviouslySubmitted,
    RejectConfirmationDialog,
    RejectionSuccessDialog,
    SignatureRejected,
    Canceled,
    Expired
};