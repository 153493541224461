import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';

const CrsRejectNotice = (props) => {
    const show = props.show;
    return (
        <Dialog open={show}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onClose}
                >
                    {props.closeButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CrsRejectNotice;