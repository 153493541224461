import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CrsQuestionList from "./CrsQuestionList";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { verifyAnswer } from "../utils/ApiCalls";
import { GenericErrorDialog, SecurityQuestionWrongAnswer } from '../generalConfiguration';
import { useDispatch } from 'react-redux';
import { showModalDialog } from '../redux/actions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        zIndex: 1,
    },
    center_content: {
        justifyContent: 'center'
    }
});

const CrsSecurityQuestions = props => {
    const show = typeof props.show === "boolean" ? props.show : true;
    const questions =
        Object.prototype.toString.call(props.questions) === "[object Array]"
            ? props.questions
            : [];
    const signerDocument = typeof props.signerDocument === "undefined" ?
        null : props.signerDocument;
    const [questionId, setQuestionId] = React.useState("");
    const [answer, setAnswer] = React.useState("");
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChangeOption = event => {
        setQuestionId(event.target.value);
    };

    const handleChangeText = event => {
        setAnswer(event.target.value);
    };

    const handleOnClickVerifyAnswerButton = (event) => {
        const personId = signerDocument.personId,
            signerType = signerDocument.signerType;
        const questionIdNum = parseInt(questionId);
        verifyAnswer(personId, signerType, questionIdNum, answer)
            .then(verificationResponse => {
                if (props.onSecurityQuestionValidated
                    && typeof props.onSecurityQuestionValidated === "function") {
                    props.onSecurityQuestionValidated(verificationResponse.authenticated);
                }
                if (verificationResponse.authenticated === false) {
                    dispatch(showModalDialog(SecurityQuestionWrongAnswer));
                }
            })
            .catch(err => {
                console.error(err);
                dispatch(showModalDialog(GenericErrorDialog));
            });
    };

    return (
        <Dialog
            className={classes.root}
            open={show === true && questions.length > 0}
            disableBackdropClick={false}
            disableEscapeKeyDown={true}
        >
            <DialogTitle component="h1">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <Typography color="textSecondary">
                    {props.content}
                </Typography>
                <FormControl component="fieldset">
                    <Grid container direction="column">
                        <Grid item>
                            <FormLabel component="legend">
                                {props.questionsLabel}
                            </FormLabel>
                        </Grid>
                        <Grid item>
                            <RadioGroup
                                aria-label="SecurityQuestion"
                                name="SecurityQuestion"
                                value={questionId}
                                onChange={handleChangeOption}
                            >
                                <CrsQuestionList
                                    questions={questions}
                                    selectedValue={questionId}
                                    onChangeText={handleChangeText}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.center_content}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!(questionId && answer)}
                    onClick={handleOnClickVerifyAnswerButton}
                >
                    {props.buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CrsSecurityQuestions;
