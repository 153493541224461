import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './CrsSignaturePanel.module.scss';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Lock from '@material-ui/icons/Lock';
import * as $ from 'jquery';
import SignatureCanvas from 'react-signature-canvas';
import { SubmitConfirmationDialog } from '../generalConfiguration';
import { RejectConfirmationDialog } from '../generalConfiguration';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { showModalDialog } from '../redux/actions';
import { GenericErrorDialog, SubmitSuccessDialog, RejectionSuccessDialog} from '../generalConfiguration';
import { getCurrentDateTime, sendSignedDocument, sendTelemetry } from "../utils/ApiCalls";
import getGeolocation from "../utils/geoLocation";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 0,
        overflowY: 'auto !important',
        display: 'none',
    },
    fr: {
        float: 'right'
    }
}));
const emptyImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=';

const CrsSignaturePanel = (props) => {
    const classes = useStyles();

    const [isCanvasForSignatureFilled, setIsCanvasForSignatureFilled] = useState(false);
    const [isCanvasForInitialsFilled, setIsCanvasForInitialsFilled] = useState(false);
    const [isActionButtonSubmit, setIsActionButtonSubmit] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isPdfSubmitted, setIsPdfSubmitted] = useState(false);
    const [signerName, setSignerName] = useState('');
    const [signerInitials, setSignerInitials] = useState('');
    const [signatureWidth, setSignatureWidth] = useState(250);
    const [sessionStart] = useState(new Date());
    const [resizeHandled, setResizeHandled] = useState(false);

    const signatureHeight = 100;
    const initialsWidth = 250;
    const initialsHeight = 100;

    var canvasForSignature = null;
    var canvasForInitials = null;

    const dispatch = useDispatch();
    const modalDialogState = useSelector(state => state.modalDialog, shallowEqual);

    var WebFont = require('webfontloader');

    // load font to be used in Auto Generate
    WebFont.load({
        google: {
            families: ['Mr Dafoe']
        }
    });

    /**
     * Resize Canvas when the window is resized
     * @param {any} event
     */
    const handleResizeCanvas = (event) => {
        let signatureWidthTemp = $("#signature-div").width() - 40;
        if (signatureWidthTemp) {
            setSignatureWidth(signatureWidthTemp);
        }
    }

    const handleAutoGenerate = (event) => {
        if (signerName && canvasForSignature) { // Auto generate signature
            let signerNameImg =
                getImageBase64FromText(signerName, signatureWidth, 50, '30px Mr Dafoe', 15, 30);
            canvasForSignature.clear();
            canvasForSignature.fromDataURL(signerNameImg,
                { width: signatureWidth, height: signatureHeight });
            setIsCanvasForSignatureFilled(true);
        }
        if (signerInitials && canvasForInitials) { // Auto generate signature
            let signerInitialsImg =
                getImageBase64FromText(signerInitials, initialsWidth, 50, '30px Mr Dafoe', 15, 30);
            canvasForInitials.clear();
            canvasForInitials.fromDataURL(signerInitialsImg,
                { width: initialsWidth, height: initialsHeight });
            setIsCanvasForInitialsFilled(true);
        }

    }

    const handleChangeSignerName = (event) => {
        let tempSignerName = event.currentTarget.value;
        setSignerName(tempSignerName);

    }

    const handleChangeSignerInitials = (event) => {
        let tempSignerInitials = event.currentTarget.value;
        setSignerInitials(tempSignerInitials);

    }

    const handleClearSignature = (event) => {
        if (canvasForSignature) {
            canvasForSignature.clear();
            setIsCanvasForSignatureFilled(false);
        }
        handleClearSignedPdf();
    }

    const handleClearInitialsButton = (event) => {
        if (canvasForInitials) {
            canvasForInitials.clear();
            setIsCanvasForInitialsFilled(false);
        }
        handleClearSignedPdf();
    }

    const handleClearSignedPdf = (event) => {
        if (props.signedPdf) {
            props.onClearSignedPdf();
        }
        let signatureData = {}
        signatureData.dateTime = emptyImage;
        if (canvasForSignature && canvasForSignature.isEmpty()) {
            signatureData.signature = emptyImage;            
        }
        if (canvasForInitials && canvasForInitials.isEmpty()) {
            signatureData.initials = emptyImage;
        }
        if (props.onApplySignature && typeof props.onApplySignature === 'function') {
            props.onApplySignature(signatureData);
            setIsActionButtonSubmit(false);
        }
    }

    const handleApplyButton = (event) => {
        let signatureData = {};
        getCurrentDateTimeAsString()
            .then(dateTimeString => {
                let dateTime =
                    getImageBase64FromText(dateTimeString, 400, 50, '35px Arial', 15, 30, 'image/png');
                signatureData.dateTime = dateTime;
                if (canvasForSignature && !canvasForSignature.isEmpty()) {
                    signatureData.signature = canvasForSignature.toDataURL('image/png');
                }
                if (canvasForInitials && !canvasForInitials.isEmpty()) {
                    signatureData.initials = canvasForInitials.toDataURL('image/png');
                }
                if (props.onApplySignature && typeof props.onApplySignature === 'function') {
                    props.onApplySignature(signatureData);
                    setIsActionButtonSubmit(true);
                } else {
                    console.error('handleApplyButton: Signature not applied');
                }
                if (props.setCloseDrawer && typeof props.setCloseDrawer === 'function') {
                    console.log('Calling setCloseDrawer...');
                    props.setCloseDrawer();
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handleSubmitButton = (event) => {
        dispatch(showModalDialog(SubmitConfirmationDialog));
    }

    const handleChangeSignatureCanvas = (event) => {
        setIsCanvasForSignatureFilled(true);
    }

    const handleChangeInitialsCanvas = (event) => {
        setIsCanvasForInitialsFilled(true);
    }

    const getImageBase64FromText = (inputText, canvasWidth, canvasHeigth, font,
        x, y, type = undefined) => {
        if (!((typeof inputText === 'string' && inputText) &&
            canvasWidth && canvasHeigth, font, x, y)) {
            console.error('getImageBase64FromText: inputText cannot be empty.');
            return;
        }
        let canvas = document.createElement("canvas");
        canvas.width = canvasWidth;
        canvas.height = canvasHeigth;
        let ctx = canvas.getContext('2d');
        ctx.font = font;
        ctx.fillText(inputText, x, y);
        let img = document.createElement("img");
        img.src = canvas.toDataURL(type);
        return img.src;
    };

    const getCurrentDateTimeAsString = () => {
        return new Promise((resolve, reject) => {
            getCurrentDateTime()
                .then(dateTime => {
                    formatDateTime(dateTime)
                        .then(dateTimeString => {
                            resolve(dateTimeString);
                        })
                        .catch(err => reject(err));
                })
                .catch(err => reject(err));
        });
    };

    const formatDateTime = (dateTime) => {
        return new Promise((resolve, reject) => {
            if (!(dateTime)) {
                reject('formatDateTime: dateTime not provided');
            }
            let dateFormatoptions = {
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: true
            };
            let dateString = new Intl.DateTimeFormat('en-US', dateFormatoptions).format(dateTime);
            resolve(dateString);
        });
    }

    const calculateSessionDuration = (sessionStart) => {
        return new Promise((resolve, reject) => {
            if (sessionStart instanceof Date) {
                let sessionEnd = new Date();
                let sessionDuration = sessionEnd - sessionStart;
                resolve(sessionDuration);
            } else {
                reject('calculateSessionDuration: sessionStart is not a Date');
            }
        });
    };

    const handleRejectButton = (event) => {
        dispatch(showModalDialog(RejectConfirmationDialog));
    }

    /**
     * Handle resize signature div
     */
    useEffect(() => {
        if (resizeHandled === false) {
            setResizeHandled(true);
            handleResizeCanvas();
            window.addEventListener("resize", handleResizeCanvas);
        }
    }, [resizeHandled]);

    /**
     * Handle send signed document and telemetry
     */
    useEffect(() => {
        const handleSendSignedDocument = (event) => {
            if (props.documentId && props.signerDocument && props.signedPdf) {
                setIsSending(true);
                let signerDocument = props.signerDocument;
                signerDocument.documentImage = props.signedPdf;
                sendSignedDocument(props.documentId, props.signerDocument)
                    .then(response => {
                        setIsPdfSubmitted(true);
                        setIsSending(false);
                        dispatch(showModalDialog(SubmitSuccessDialog));
                    })
                    .catch(err => {
                        console.error('handleSubmitButton', err);
                        setIsSending(false);
                        dispatch(showModalDialog(GenericErrorDialog));
                    });
            } else {
                console.error('handleSubmitButton: Necessary information not given.');
                dispatch(showModalDialog(GenericErrorDialog));
            }
        };

        const handleSendTelemetry = (event) => {
            if (props.signerDocument) {

                
                Promise.allSettled(
                    [getGeolocation(), calculateSessionDuration(sessionStart)]
                )
                    .then(telemetryDataSources => {
                        const [geoLocationData, sessionDuration] = telemetryDataSources;
                        var location = "";
                        var duration = 0;
                        if (geoLocationData.status === "rejected") {
                            console.info("geolocation not enabled");
                        } else {
                            location = geoLocationData.value.location;
                        }
                        if (sessionDuration.status === "rejected") {
                            console.info("sessionDuration calculation failed");
                        } else {
                            duration = sessionDuration.value;
                        }
                        let telemetryData =
                        {
                            signatureRequestId: props.signerDocument.signatureRequestId,
                            requestSignerId: props.signerDocument.requestSignerId,
                            signerNumber: props.signerDocument.signerNumber,
                            uniqueId: props.signerDocument.uniqueId,
                            geoLocation: location,
                            sessionDuration: duration
                        }
                        sendTelemetry(telemetryData)
                            .then(data => console.info('Telemetry sent successfully. Data:', data))
                            .catch(err => console.error(err));
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        };

        const handleSendRejectedDocument = (event) => {
            if (props.documentId && props.signerDocument) {
                setIsSending(true);
                let signerDocument = props.signerDocument;
                signerDocument.SignerRejected = true;
                sendSignedDocument(props.documentId, props.signerDocument)
                    .then(response => {
                        setIsPdfSubmitted(true);
                        setIsActionButtonSubmit(true);
                        setIsSending(false);
                        dispatch(showModalDialog(RejectionSuccessDialog));
                    })
                    .catch(err => {
                        console.error('handleSendRejectedDocument', err);
                        setIsSending(false);
                        dispatch(showModalDialog(GenericErrorDialog));
                    });
            } else {
                console.error('handleSubmitButton: Necessary information not given.');
                dispatch(showModalDialog(GenericErrorDialog));
            }
        };

        if (modalDialogState.modalDialogOutput &&
            modalDialogState.modalDialogOutput.source &&
            modalDialogState.modalDialogOutput.source === 'SignaturePanel.SubmitConfirmationDialog'
        ) {
            if (modalDialogState.modalDialogOutput.isAccepted && !isSending && !isPdfSubmitted) {
                handleSendSignedDocument();
                handleSendTelemetry();
            }
        }
        if (modalDialogState.modalDialogOutput &&
            modalDialogState.modalDialogOutput.source &&
            modalDialogState.modalDialogOutput.source === 'SignaturePanel.RejectConfirmationDialog'
        ) {
            if (modalDialogState.modalDialogOutput.isAccepted && !isSending && !isPdfSubmitted) {
                handleSendRejectedDocument();
                handleSendTelemetry();
            }
        }

    });



    return (
        <div className={styles.signature}>
       
            <Grid container className={styles['signature__grid']} >
                <div className={styles['signature__grid--blur']}>
                    <Grid item xs={12} >
                        <div className="sig p-40">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <h5>Digital Signature</h5>
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        <Button color="primary" className={classes.fr} onClick={handleAutoGenerate}
                                            disabled={(!(props.pdfContainsInitials ? signerName && signerInitials : signerName)) || (isPdfSubmitted === true)}>
                                            Auto-Generate
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <label>Please scroll to the bottom of the PDF in order to unlock the following Signature options.</label>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Full Name"
                                        color="secondary"
                                        fullWidth
                                        align="left"
                                        variant="filled"
                                        onChange={handleChangeSignerName}
                                        value={signerName}
                                        disabled={!props.isDocumentRead}
                                        inputProps={{ maxLength: 55 }}
                                        InputProps={props.isDocumentRead ? {} :
                                            {
                                                endAdornment: <InputAdornment position="end"><Lock /></InputAdornment>
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    {props.pdfContainsInitials ?
                                        (
                                            <TextField
                                                label="Initials"
                                                color="secondary"
                                                align="right"
                                                variant="filled"
                                                onChange={handleChangeSignerInitials}
                                                disabled={!(props.isDocumentRead && signerName)}
                                                inputProps={{ maxLength: 4 }}
                                                InputProps={!(props.isDocumentRead) ?
                                                    {
                                                        endAdornment: <InputAdornment position="end"><Lock /></InputAdornment>,
                                                    } : {}
                                                }
                                                value={signerInitials}
                                            />)
                                        : (null)
                                    }
                                </Grid>
                            </Grid>
                            <div id="signature-div" className={`$styles['signature__grid-canvas']} + mt-20`}>
                                {signerName ? (
                                    <div>
                                        <div className="sig-pad" style={{ width: signatureWidth + 30 + 'px', height: signatureHeight + 88 + 'px' }}>
                                            <label>Signature</label>
                                            <SignatureCanvas
                                                clearOnResize={false}
                                                penColor='black'
                                                canvasProps={{ width: signatureWidth, height: signatureHeight, color: "white", className: 'sigCanvas', border: 'white' }}
                                                ref={(ref) => { canvasForSignature = ref }}
                                                onEnd={handleChangeSignatureCanvas}
                                                disabled={(isPdfSubmitted === true)}/>
                                            <Button className="fr" onClick={handleClearSignature}
                                                disabled={!(isCanvasForSignatureFilled) || (isPdfSubmitted === true)}>
                                                Clear
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                                {signerName && signerInitials ? (
                                    <div>
                                        <div className="mt-20 sig-pad" style={{ width: initialsWidth + 30 + 'px', height: initialsHeight + 88 + 'px' }}>
                                            <label>Initials</label>
                                            <SignatureCanvas
                                                clearOnResize={false}
                                                penColor='black'
                                                canvasProps={{ width: initialsWidth, height: initialsHeight, className: 'sigCanvas', border: 'white' }}
                                                ref={(ref) => { canvasForInitials = ref }}
                                                onEnd={handleChangeInitialsCanvas}
                                                disabled={(isPdfSubmitted === true)}/>
                                            <Button className="fr" onClick={handleClearInitialsButton}
                                                disabled={!(isCanvasForInitialsFilled) || (isPdfSubmitted === true)}>
                                                Clear
                                            </Button>
                                        </div>

                                    </div>
                                ) : null}

                                <div className={styles['signature__actions']}>
                                   
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" float="left" disabled={(isSending === true) || !(isPdfSubmitted === false)} onClick={handleRejectButton}>
                                                Reject
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                id="action-button-signature"
                                                variant="contained"
                                                color="primary"
                                                className={classes.fr}
                                                disabled={
                                                    ((isSending === true) ||
                                                        !(isActionButtonSubmit ?
                                                            // Button is Submit => BEGIN
                                                            isPdfSubmitted === false
                                                            // Button is Submit => END
                                                            :
                                                            // Button is Apply => BEGIN
                                                            props.pdfContainsInitials ?
                                                                // PDF contains Initials => BEGIN
                                                                isCanvasForSignatureFilled &&
                                                            isCanvasForInitialsFilled &&
                                                                isPdfSubmitted === false
                                                                // PDF contains Initials => BEGIN
                                                                :
                                                                // PDF does not contain Initials => BEGIN
                                                            isCanvasForSignatureFilled &&
                                                            isPdfSubmitted === false
                                                        // PDF does not contain Initials => END
                                                        // Button is Apply => END
                                                    ))
                                                }
                                                onClick={isActionButtonSubmit ?
                                                    handleSubmitButton : handleApplyButton}
                                            >
                                                {isActionButtonSubmit ?
                                                   'Submit' : 'Apply'}
                                            </Button>
                                            
                                        </Grid>
                                    </Grid>
                                    <Backdrop className={classes.backdrop} open={isSending} >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </div>
            </Grid>
        </div>
    );
}

export default CrsSignaturePanel;