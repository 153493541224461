import { useState, useEffect } from "react";
import { getQuestions } from "../utils/ApiCalls";

const CrsGetSecurityQuestions = props => {
    const [isGetDocumentCalled, setIsGetDocumentCalled] = useState(false);
    const signerDocument = typeof props.signerDocument === "undefined" ?
        null : props.signerDocument;

    useEffect(() => {
        if (isGetDocumentCalled === false && signerDocument !== null) {
            setIsGetDocumentCalled(true);
            const personId = signerDocument.personId,
                signerType = signerDocument.signerType;
            getQuestions(personId, signerType)
                .then(questions => {
                    if (
                        props.onGetQuestions &&
                        typeof props.onGetQuestions === "function"
                    ) {
                        props.onGetQuestions(questions);
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [isGetDocumentCalled, signerDocument, props]);

    //Does not render anything, just getDocument and set it to a state
    return null;
};

export default CrsGetSecurityQuestions;
