import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSignerDocument } from '../utils/ApiCalls';
import { showModalDialog } from '../redux/actions';
import { GenericErrorDialog } from '../generalConfiguration';

const CrsGetSignerDocument = (props) => {
    const [isGetDocumentCalled, setIsGetDocumentCalled] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.execute === true && props.documentId && !isGetDocumentCalled) {
            setIsGetDocumentCalled(true);
            getSignerDocument(props.documentId)
                .then(signerDocument => {
                    if (props.onGetSignerDocument && typeof props.onGetSignerDocument === 'function') {
                        props.onGetSignerDocument(signerDocument);
                    }
                })
                .catch(err => {
                    console.error(err);
                    dispatch(showModalDialog(GenericErrorDialog));
                });
        }
    }, [props, isGetDocumentCalled, dispatch]);

    //Does not render anything, just getDocument and set it to a state
    return null;
};

export default CrsGetSignerDocument;