import React,
{
    useRef,
    useEffect,
    useState
} from 'react';
import WebViewer from '@pdftron/webviewer';
import * as $ from 'jquery';
import { useDispatch } from 'react-redux';
import { showModalDialog } from '../redux/actions';
import { GenericErrorDialog } from '../generalConfiguration';

const AnnotationType = {
    Signature: 'Signature',
    Initials: 'Initials',
    Date: 'Date'
};

const CrsPdfPanel = (props) => {
    const viewer = useRef(null);
    const dispatch = useDispatch();

    const [initializationDone, setInitializationDone] = useState(false);
    const [annotations, setAnnotations] = useState(null);
    const [annotationsManager, setAnnotationsManager] = React.useState(null);
    const [documentViewer, setDocumentViewer] = useState(null);

    const handleOnUserScrollPdf = (onUserScrollPdf, onLoadPdfData) => {
        // detect PDFTron scroll to bottom
        let iframe = $('[id^=webviewer]').contents();
        if (!iframe) {
            console.error('handleOnScrollDocument: webviewer not found.');
            return;
        }
        let documentContainer = $('.DocumentContainer', iframe);
        let document = $('.document', documentContainer);
        $('.DocumentContainer', iframe).scroll(() => {
            let scroll = documentContainer.scrollTop();
            if (Math.round(scroll) >= (document[0].scrollHeight - documentContainer[0].clientHeight - 8)) { //CLAIM-158 WB 4/30/2021
                if (onUserScrollPdf && typeof onUserScrollPdf === 'function') {
                    onUserScrollPdf(onLoadPdfData);
                }
            }
        });
    };

    const findAnnotations = (annotations, annotationType, signer = undefined) => {
        let annotationsFound = [];
        if (!(annotations && annotationType)) {
            console.error('Required info not provided');
            return annotationsFound;
        }
        annotationsFound = annotations.filter(annot => {
            let annotationFound = false;
            let data = null;
            if (annot.fieldName) {
                try {
                    data = JSON.parse(annot.fieldName)[0];
                } catch (e) {
                    console.error('findAnnotations: annotation field name not found');
                }
            }
            if (data) {
                annotationFound = data.Type === annotationType;
                if (annotationFound && signer) {
                    annotationFound = annotationFound && data.signer === signer;
                }
            }
            return annotationFound;
        });
        return annotationsFound;
    }

    const configurePdfTools = () => {
        let iframe = $('[id^=webviewer]').contents();
        $("[data-element='shapeToolGroupButton'", iframe).css('display', 'none');
        $("[data-element='eraserToolButton'", iframe).css('display', 'none');
        $("[data-element='signatureToolButton'", iframe).css('display', 'none');
        $("[data-element='selectToolButton'", iframe).css('display', 'none');
        $("[data-element='freeTextToolButton'", iframe).css('display', 'none');
        $("[data-element='stickyToolButton'", iframe).css('display', 'none');
        $("[data-element='toolsButton'", iframe).css('display', 'none');
        $('.divider', iframe).css('display', 'none');
        $("[data-element='panToolButton'", iframe).click();
        $("[data-element='panToolButton'", iframe).css('display', 'none');
        $("[data-element='freeHandToolGroupButton'", iframe).css('display', 'none');
    };

    const base64ToBlob = (base64) => {
        return new Promise((resolve, reject) => {
            if (!base64) {
                reject(`base64 not provided`);
            }
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; ++i) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            let blob = new Blob([bytes], { type: 'application/pdf' });
            resolve(blob);
        });
    };

    const applySignatureData = (signatureData) => {
        //set false when applying because it is set to read only after it is applied to the PDF. Used when the redo the signature.
        annotationsManager.setReadOnly(false);

        if ((signatureData && signatureData.dateTime && signatureData.signature)=== false) {
            console.error('applySignatureData: Required info not provided.');
            dispatch(showModalDialog(GenericErrorDialog));
        }
        applyDateAnnotation(documentViewer, annotations, annotationsManager,
            props.signerDocument.signerNumber, signatureData.dateTime);
        if (signatureData.signature) {
        applySignatureAnnotation(documentViewer, annotations, annotationsManager,
            props.signerDocument.signerNumber, signatureData.signature);
        }
        if (signatureData.initials) {
            applyInitialsAnnotation(documentViewer, annotations, annotationsManager,
                props.signerDocument.signerNumber, signatureData.initials);
        }
    };

    const applySignatureAnnotation = (documentViewer, annotations, annotationsManager,
        signerNumber, signature) => {
        if (!(documentViewer && annotations && annotationsManager && signerNumber)) {
            console.error('applySignatureAnnotation: signaturaData not provided');
            return;
        }
        if (documentViewer && documentViewer.getAnnotationsLoadedPromise()) {
            documentViewer.getAnnotationsLoadedPromise().then(() => {
                let currentAnnotations = annotationsManager.getAnnotationsList();
                let signatureAnnotations = findAnnotations(currentAnnotations, AnnotationType.Signature, signerNumber);
                if (signatureAnnotations.length <= 0) {
                    console.error('applySignatureAnnotation: Annotations not found.');
                }
                signatureAnnotations.forEach(signatureAnnotation => {
                    annotationsManager.deleteAnnotation(signatureAnnotation);
                    let shift = calculateAnnotationShift(AnnotationType.Signature,
                        signatureAnnotation.ImageData);
                    createStampAnnotation(annotations, annotationsManager, signatureAnnotation,
                        138, 33, shift.X, shift.Y, signature);
                });
            });
        }
    }

    const applyInitialsAnnotation = (documentViewer, annotations, annotationsManager, signerNumber, initials) => {
        if (!(documentViewer && annotations)) {
            console.error('applyInitialsAnnotation: Required info not provided.');
            return;
        }
        if (documentViewer && documentViewer.getAnnotationsLoadedPromise()) {
            documentViewer.getAnnotationsLoadedPromise().then(() => {
                let currentAnnotations = annotationsManager.getAnnotationsList();
                let initialsAnnotations = findAnnotations(currentAnnotations, AnnotationType.Initials, signerNumber);
                if (initialsAnnotations.length <= 0) {
                    console.error('applyInitialsAnnotation: Annotations not found.');
                }
                initialsAnnotations.forEach(initialsAnnotation => {
                    annotationsManager.deleteAnnotation(initialsAnnotation);
                    let shift = calculateAnnotationShift(AnnotationType.Initials,
                        initialsAnnotation.ImageData);
                    createStampAnnotation(annotations, annotationsManager, initialsAnnotation,
                        66, 33, shift.X, shift.Y, initials);
                });
            });
        }
    }

    const applyDateAnnotation = (documentViewer, annotations, annotationsManager, signerNumber, dateTime) => {
        if (!(documentViewer && annotations && annotationsManager && signerNumber)) {
            console.error('applyDateAnnotation: Required info not provided');
            return;
        }
        // If can load annotations
        if (documentViewer && documentViewer.getAnnotationsLoadedPromise()) {
            documentViewer.getAnnotationsLoadedPromise().then(() => {
                let initialPdfAnnotations = annotationsManager.getAnnotationsList();
                let dateAnnotations = findAnnotations(initialPdfAnnotations, AnnotationType.Date, signerNumber);
                if (dateAnnotations.length <= 0) {
                    console.error('applyDateAnnotation: Annotations not found.');
                }
                dateAnnotations.forEach(dateAnnotation => {
                    annotationsManager.deleteAnnotation(dateAnnotation);
                    let shift = calculateAnnotationShift(AnnotationType.Date, dateAnnotation.ImageData);
                    createStampAnnotation(annotations, annotationsManager, dateAnnotation,
                        70, 10, shift.X, shift.Y, dateTime);
                });
            });
        }
    }

    const calculateAnnotationShift = (annotationType, annotationData) => {
        let shift = {
            X: 0,
            Y: 0
        };
        switch (annotationType) {
            case AnnotationType.Signature:
                if (annotationData === undefined) {
                    shift.X = 0;
                    shift.Y = -25;
                }
                break;
            case AnnotationType.Initials:
                if (annotationData === undefined) {
                    shift.X = 0;
                    shift.Y = -25;
                }
                break;
            case AnnotationType.Date:
                if (annotationData === undefined) {
                    shift.X = -5;
                    shift.Y = -13;
                }
                break;
            default:
                console.error(`calculateAnnotationShift: Annotatation type non supported: ${annotationType}`);
        }
        return shift;
    }

    const createStampAnnotation =
        (annotations, annotationsManager, currentAnnotation, width, height, xShift, yShift, imageData) => {
            if (!(annotations && annotationsManager && currentAnnotation)) {
                console.error('createStampAnnotation: Required infor not provided.');
                return;
            }
            const stampAnnot = new annotations.StampAnnotation();
            stampAnnot.PageNumber = currentAnnotation.PageNumber;
            stampAnnot.X = currentAnnotation.X + xShift;
            stampAnnot.Y = currentAnnotation.Y + yShift;
            stampAnnot.Width = width;
            stampAnnot.Height = height;
            stampAnnot.fieldName = currentAnnotation.fieldName;
            stampAnnot.ImageData = imageData;
            stampAnnot.Author = annotationsManager.getCurrentUser();
            annotationsManager.addAnnotation(stampAnnot);
            annotationsManager.redrawAnnotation(stampAnnot);
        };

    /**
     * Initialize pdf settings and load document
     */
    useEffect(() => {
        const webViewerDisabledElements =
            [
                'AnnotationEdit',
                'TextSelect',
                'CropPage',
                'AnnotationEraserTool',
                'AnnotationCreateSticky',
                'AnnotationCreateFreeHand',
                'AnnotationCreateTextHighlight',
                'AnnotationCreateTextUnderline',
                'AnnotationCreateTextSquiggly',
                'AnnotationCreateTextStrikeout',
                'AnnotationCreateFreeText',
                'AnnotationCreateCallout',
                'AnnotationCreateSignature',
                'AnnotationCreateLine',
                'AnnotationCreateArrow',
                'AnnotationCreatePolyline',
                'AnnotationCreateStamp',
                'AnnotationCreateRectangle',
                'AnnotationCreateEllipse',
                'AnnotationCreatePolygon',
                'AnnotationCreatePolygonCloud',
                'AnnotationCreateDistanceMeasurement',
                'AnnotationCreatePerimeterMeasurement',
                'AnnotationCreateAreaMeasurement',
                'textToolGroupButton',
                'miscToolGroupButton',
                'outlinesPanelButton',
                'notesPanelButton',
                'annotationCommentButton',
                'annotationStyleEditButton',
                'annotationDeleteButton',
                'annotationCropButton',
                'annotationRedactButton',
                'annotationGroupButton',
                'annotationUngroupButton',
                'calibrateButton',
                'linkButton',
                'fileAttachmentDownload',
                'notesPanel',
                'highlightToolButton',
                'freeHandToolButton',
                'copyTextButton',
                'textHighlightToolButton',
                'textUnderlineToolButton',
                'textSquigglyToolButton',
                'textStrikeoutToolButton',
                'signatureModal', // Bug Story 79307 AWade 3/15/21
                'freeHandToolGroupButton' // Bug Story 79307 AWade 3/15/21
            ];

        if (props.signerDocument && !initializationDone) {
            setInitializationDone(true);
            WebViewer(
                {
                    path: '/webviewer/lib',
                    disableLogs: true,
                    disabledElements: webViewerDisabledElements,
                    licenseKey: 'Crop Risk Services, Inc.(cropriskservices.com):OEM:see agreement::B+:AMS(20220911):56A57EFD04D7F60A7360B13AC9A2737860614F8BDF707892FB245B82BD025C903AF4B6F5C7'
                },
                viewer.current
            ).then(async (instance) => { // On WebViewer ready
                configurePdfTools();
                const { Annotations, annotManager, docViewer } = instance;
                setAnnotations(Annotations);
                setAnnotationsManager(annotManager);
                setDocumentViewer(docViewer);
                base64ToBlob(props.signerDocument.documentImage)
                    .then(pdfBlob => {
                        instance.loadDocument(pdfBlob, {
                            extension: "PDF" });
                    })
                    .catch(err => {
                        console.error(err);
                        //TODO: show error
                    });
                
                docViewer.on('documentLoaded', () => {
                    docViewer.getAnnotationsLoadedPromise().then(() => {
                        let initialTempPdfAnnotations = annotManager.getAnnotationsList();
                        let pdfData = {};
                        let tempInitialsAnnotations =
                            findAnnotations(initialTempPdfAnnotations, AnnotationType.Initials,
                                props.signerDocument.signerNumber);
                        if (tempInitialsAnnotations.length > 0) {
                            pdfData.includesInitials = true;
                        } else {
                            pdfData.includesInitials = false;
                        }
                        handleOnUserScrollPdf(props.onUserScrollPdf, pdfData);
                    });
                });
            });
        }
    }, [props, initializationDone]);

    /**
     * Apply signature
     */
    useEffect(() => {
        const getSignedPdfAsBase64 = (annotationsManager, documentViewer) => {
            const pdfMarker = ';base64,';
            return new Promise((resolve, reject) => {
                annotationsManager.exportAnnotations({ widgets: true, links: true, fields: true })
                    .then(annotations => {
                        const doc = documentViewer.getDocument();
                        doc.getFileData({ xfdfString: annotations })
                            .then(data => {
                                const arr = new Uint8Array(data);
                                const blob = new Blob([arr]);
                                const reader = new FileReader();
                                reader.addEventListener('load', () => {
                                    let rawPdfBase64 = reader.result;
                                    let posFound = rawPdfBase64.indexOf(pdfMarker);
                                    let startPos = posFound + pdfMarker.length;
                                    let pdfBase64 = rawPdfBase64.slice(startPos);
                                    resolve(pdfBase64);
                                });
                                reader.readAsDataURL(blob);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        };

        // Applying signature
        if (annotations && annotationsManager && documentViewer && props.signatureData) {
            let signatureData = props.signatureData;
            applySignatureData(signatureData);
            getSignedPdfAsBase64(annotationsManager, documentViewer)
                .then(pdfBase64 => {
                    if (props.onCreateSignedPdf && typeof props.onCreateSignedPdf == 'function') {
                        props.onCreateSignedPdf(pdfBase64);
                        annotationsManager.setReadOnly(true);
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
    });

    return (
        <div id='pdf-tron' style={{ height: `calc(100vh - ` + $('header').height() + `px)` }} ref={viewer}>
        </div>
    );
}

export default CrsPdfPanel;