import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

const CrsQuestionItem = props => {
    const show = typeof props.show === "boolean" ? props.show : true;

    if (show === false) {
        return null;
    }

    return (
        <>
            <FormControlLabel
                control={<Radio />}
                label={props.radioLabel}
                value={props.radioValue}
            />
            {props.radioValue === props.selectedValue ? (
                <TextField
                    color="secondary"
                    align="left"
                    variant="filled"
                    label={props.textLabel}
                    onChange={props.onChangeText}
                    type={props.type}
                    placeholder={props.placeholder}
                />
            ) : null}
        </>
    );
};

export default CrsQuestionItem;
