import React, { useState } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import {
    Link
} from "react-router-dom";

const theme = createMuiTheme({
});

const CrsBodyDrawer = (props) => {
    const drawerWidth = props.drawerWidth;
    const show = props.show;
    const toolbarStyle = props.toolbarStyle;
    const useStyles = makeStyles((theme) => ({
        drawer: {
            display: 'none',
            overflowX: 'hidden',
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: toolbarStyle //Taken from caller
    }));

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    if (show) {
        return (
            <Drawer color="secondary"
                variant="permanent"

                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div color="secondary" className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <List color="primary">
                    <Link tag={Link} to='/home'>
                        <ListItem button key='home' onClick={handleDrawerClose} >
                            <ListItemIcon className={classes.drawerIcon} color="primary"><HomeIcon style={{ fontSize: 35 }} color="secondary"></HomeIcon></ListItemIcon>
                            <ListItemText className={classes.drawerContent} primary="Home" color="secondary" />
                        </ListItem>
                    </Link>
                    <Link tag={Link} to="/pdf" color="secondary">
                        <ListItem button key='PDFTron' onClick={handleDrawerClose}>
                            <ListItemIcon className={classes.drawerIcon}><BorderColorIcon style={{ fontSize: 35 }} color="secondary" ></BorderColorIcon></ListItemIcon>
                            <ListItemText className={classes.drawerContent} primary="PDFTron" />
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
        );
    } else {
        return null;
    }

};

export default CrsBodyDrawer;