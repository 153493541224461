export const showModalDialog = (modalDialogInput) => {
    return {
        type: 'MODAL_DIALOG_SHOW',
        payload: modalDialogInput
    };
}

export const closeModalDialog = (modalDialogOutput) => {
    return {
        type: 'MODAL_DIALOG_CLOSED',
        payload: modalDialogOutput
    };
}