import React from "react";
import CrsQuestionItem from "./CrsQuestionItem";

const CrsQuestionList = props => {
    const show = typeof props.show === "boolean" ? props.show : true;
    const questions =
        Object.prototype.toString.call(props.questions) === "[object Array]"
            ? props.questions
            : [];
    if (show === false || questions.length <= 0) {
        return null;
    }
    const questionList = questions.map(question => (
        <CrsQuestionItem
            key={`${question.questionId}`}
            radioLabel={question.questionText}
            radioValue={`${question.questionId}`}
            textLabel={question.questionText}
            onChangeText={props.onChangeText}
            selectedValue={props.selectedValue}
            type={question.htmlType}
            placeholder={question.htmlPlaceholder}
        />
    ));
    return questionList;
};

export default CrsQuestionList;
