import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from "@material-ui/core/FormLabel";
import { verifyToken } from "../utils/ApiCalls";
import { GenericErrorDialog, VerificationTokenWrongAnswer } from '../generalConfiguration';
import { useDispatch } from 'react-redux';
import { showModalDialog } from '../redux/actions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        zIndex: 1,
    },
    center_content: {
        justifyContent: 'center'
    }
});

const CrsVerifySecurityToken = (props) => {
    const show = typeof props.show === "boolean" ? props.show : true;
    const signerDocument = typeof props.signerDocument === "undefined" ?
        null : props.signerDocument;
    const [securityToken, setSecurityToken] = useState('');
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleOnChangeSecurityToken = event => {
        setSecurityToken(event.target.value);
    };

    const handleVerifySecurityToken = event => {
        const personId = signerDocument.personId;
        verifyToken(personId, securityToken)
            .then(verificationResponse => {
                if (props.onSecurityTokenValidated &&
                    typeof props.onSecurityTokenValidated === "function") {
                    props.onSecurityTokenValidated(verificationResponse.authenticated);
                }
                if (verificationResponse.authenticated === false) {
                    dispatch(showModalDialog(VerificationTokenWrongAnswer));
                }
            })
            .catch(err => {
                console.error(err);
                dispatch(showModalDialog(GenericErrorDialog));
            });
    };

    return (
        <Dialog
            className={classes.root}
            open={show === true && signerDocument !== null}
            disableBackdropClick={false}
            disableEscapeKeyDown={true}
        >
            <DialogTitle component="h1">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <Typography color="textSecondary">
                    {props.content}
                </Typography>
                <FormLabel component="legend">
                    {props.securityTokenComment}
                </FormLabel>
                <TextField
                    label={props.inputTextLabel}
                    color="secondary"
                    align="center"
                    variant="filled"
                    type="password"
                    required={true}
                    onChange={handleOnChangeSecurityToken}
                />
            </DialogContent>
            <DialogActions className={classes.center_content}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!securityToken}
                    onClick={handleVerifySecurityToken}
                >
                    {props.verifyButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CrsVerifySecurityToken;