import React from 'react';
import Card from '@material-ui/core/Card';
import { CardContent, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Canceled } from '../generalConfiguration';
import styles from './CrsMainPage.module.scss';

const CrsDocumentCanceled = (props) => {
    const show = props.show;
    if (show) {

        return (
            <>
                <Card className={styles['card']}>
                    <div className={styles['card--blur']}>
                        <CardHeader title={Canceled.title} />
                        <CardContent className={styles['card--content']}>
                            <h2 className={styles['card--h']} >
                                {Canceled.title}
                            </h2>
                            <Typography>
                                {Canceled.content}
                            </Typography>
                        </CardContent>
                    </div>
                </Card>
            </>
        );

    }
    else {
        return null;
    }
};

export default CrsDocumentCanceled;