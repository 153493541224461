import React, { useState } from "react";
import CrsGetSecurityQuestions from "./CrsGetSecurityQuestions";
import CrsSecurityQuestions from "./CrsSecurityQuestions";
import { useDispatch } from 'react-redux';
import {
    GenericErrorDialog,
    SecurityQuestions,
    SecurityToken
} from '../generalConfiguration';
import { showModalDialog } from '../redux/actions';
import CrsSendSecurityToken from "./CrsSendSecurityToken";
import CrsVerifySecurityToken from "./CrsVerifySecurityToken";

const CrsAuthentication = props => {
    const show = typeof props.show === "boolean" ? props.show : true;
    const signerDocument = typeof props.signerDocument === "undefined" ?
        null : props.signerDocument;
    const [questions, setQuestions] = useState([]);
    const [isSecurityQuestionPassed, setIsSecurityQuestionPassed] = useState(false);
    const [isTokenSent, setIsTokenSent] = useState(false);
    const [isSecurityTokenPassed, setIsSecurityTokenPassed] = useState(false);

    const dispatch = useDispatch();

    const handleOnGetQuestions = questions => {
        setQuestions(questions);
    };

    const handleOnTokenSent = success => {
        setIsTokenSent(success);
        if (success === false) {
            dispatch(showModalDialog(GenericErrorDialog));
        }
    };

    const handleOnSecurityQuestionValidated = (success) => {
        setIsSecurityQuestionPassed(success);
    };

    const handleOnSecurityTokenValidated = (passed) => {
        setIsSecurityTokenPassed(passed);
        if (props.onUserAuthenticated
            && typeof props.onUserAuthenticated === "function") {
            props.onUserAuthenticated(passed);
        }
    };

    if (show === false || signerDocument === null) {
        return null;
    }

    return (
        <div id="CrsAuthentication">
            <CrsGetSecurityQuestions
                signerDocument={signerDocument}
                onGetQuestions={handleOnGetQuestions}
            />
            <CrsSecurityQuestions
                show={signerDocument !== null && isSecurityQuestionPassed === false}
                signerDocument={signerDocument}
                questions={questions}
                title={SecurityQuestions.title}
                content={SecurityQuestions.content}
                questionsLabel={SecurityQuestions.questionsLabel}                
                buttonLabel={SecurityQuestions.verifyButtonLabel}
                onSecurityQuestionValidated={handleOnSecurityQuestionValidated}
            />
            <CrsSendSecurityToken
                signerDocument={signerDocument}
                execute={isSecurityQuestionPassed}
                onTokenSent={handleOnTokenSent}
            />
            <CrsVerifySecurityToken
                show={isTokenSent && !isSecurityTokenPassed}
                signerDocument={signerDocument}
                title={SecurityToken.title}
                content={SecurityToken.content}
                inputTextLabel={SecurityToken.inputTextLabel}
                securityTokenComment={SecurityToken.securityTokenComment}
                verifyButtonLabel={SecurityToken.verifyButtonLabel}
                onSecurityTokenValidated={handleOnSecurityTokenValidated}
            />
        </div>
    );
};

export default CrsAuthentication;
