import React from 'react';
import Card from '@material-ui/core/Card';
import { CardContent, CardActions, Button, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styles from './CrsMainPage.module.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    ackcontent: {
        textAlign: "justify",
        textJustify: "inter-word",
        whiteSpace: "pre-wrap"
    }
});

const CrsAcknowledgement = (props) => {
    const show = props.show;
    const classes = useStyles();

    if (show) {
        return (
            <>
                <Card>
                    <CardHeader title={props.title} component="h2" />
                    <CardContent>
                        <Typography className={classes.ackcontent}>
                            {props.content}
                        </Typography>
                    </CardContent>
                    <CardActions className={styles['action']}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.onAccept}>
                            {props.acceptButtonLabel}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.onReject}>
                            {props.rejectButtonLabel}
                        </Button>
                    </CardActions>
                </Card>
            </>
        );
    } else {
        return null;
    }
}

export default CrsAcknowledgement;