import React from 'react';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from '@material-ui/core/Grid';
import CrsPdfPanel from './CrsPdfPanel';
import CrsSignaturePanel from './CrsSignaturePanel';
import CrsSignatureDrawer from './CrsSignatureDrawer';
import styles from './CrsMainPage.module.scss';

const CrsMainGrid = (props) => {
    const show = props.show;
    const isXLarge = useMediaQuery("(min-width:1920px)");
    const isLarge = useMediaQuery("(min-width:1280px)");
    let mainGrid = null;
    if (isLarge || isXLarge) {
        mainGrid = (
            <>
                <Grid container spacing={0} className={styles.home}>
                    <Grid item xs={7} >
                        <CrsPdfPanel
                            documentId={props.documentId}
                            signerDocument={props.signerDocument}
                            signatureData={props.signatureData}
                            onUserScrollPdf={props.onUserScrollPdf}
                            onCreateSignedPdf={props.onCreateSignedPdf}
                        />
                    </Grid>
                    <Grid item xs={5} >
                        <CrsSignaturePanel
                            documentId={props.documentId}
                            signerDocument={props.signerDocument}
                            isDocumentRead={props.isDocumentRead}
                            pdfContainsInitials={props.pdfContainsInitials}
                            signedPdf={props.signedPdf}
                            onApplySignature={props.onApplySignature}
                            onCreateSignedPdf={props.onCreateSignedPdf}
                            onClearSignedPdf={props.onClearSignedPdf}
                        />
                    </Grid>
                </Grid>
            </>
        );
    } else {
        mainGrid = (
            <>
                <CrsPdfPanel
                    documentId={props.documentId}
                    signerDocument={props.signerDocument}
                    signatureData={props.signatureData}
                    onUserScrollPdf={props.onUserScrollPdf}
                    onCreateSignedPdf={props.onCreateSignedPdf}
                />
                <CrsSignatureDrawer
                    documentId={props.documentId}
                    signerDocument={props.signerDocument}
                    isDocumentRead={props.isDocumentRead}
                    pdfContainsInitials={props.pdfContainsInitials}
                    signedPdf={props.signedPdf}
                    onApplySignature={props.onApplySignature}
                    onClearSignedPdf={props.onClearSignedPdf}
                />
            </>
        );
    }
    if (show) {
        return (
            <div id='crs-main-grid'>
                {mainGrid}
            </div>
        );
    } else {
        return null;
    }
};

export default CrsMainGrid;