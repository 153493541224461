import React from 'react';
import Card from '@material-ui/core/Card';
import { CardContent, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { InvalidUrlNotice } from '../generalConfiguration';
import styles from './CrsMainPage.module.scss';

const CrsInvalidUrl = (props) => {
    return (
        <Card className={styles['card']}>
            <div className={styles['card--blur']}>
                <CardHeader title={InvalidUrlNotice.title} />
                <CardContent className={styles['card--content']}>
                    <h2 className={styles['card--h']} >
                        {InvalidUrlNotice.title}
                    </h2>
                    <Typography>
                        {InvalidUrlNotice.content}
                    </Typography>
                </CardContent>
            </div>
        </Card>
    );
};

export default CrsInvalidUrl;