let initialState = {
    modalDialogInput: null,
    modalDialogOutput: null
};

const modalDialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MODAL_DIALOG_SHOW':
            return Object.assign({}, state, {
                modalDialogInput: action.payload
            });
        case 'MODAL_DIALOG_CLOSED':
            return Object.assign({}, state, {
                modalDialogOutput: action.payload
            });
        default:
            return state;
    }
};

export default modalDialogReducer;