import { useState, useEffect } from "react";
import { sendToken } from "../utils/ApiCalls";
import { useDispatch } from 'react-redux';
import { GenericErrorDialog } from '../generalConfiguration';
import { showModalDialog } from '../redux/actions';

const CrsSendSecurityToken = (props) => {
    const execute = props.execute;
    const signerDocument = typeof props.signerDocument === "undefined" ?
        null : props.signerDocument;
    const [isTokenSent, setIsTokenSent] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (execute === true && signerDocument !== null && isTokenSent === false) {
            sendToken(signerDocument.personId)
                .then(sendTokenResponse => {
                    setIsTokenSent(true);
                    if (props.onTokenSent && typeof props.onTokenSent == "function") {
                        props.onTokenSent(sendTokenResponse.success);
                    }
                })
                .catch(err => {
                    console.error(err);
                    dispatch(showModalDialog(GenericErrorDialog));
                });
        }
    });

    // Does not render anything
    return null;
};

export default CrsSendSecurityToken;