import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import CrsSignaturePanel from './CrsSignaturePanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(3),

    },
    list: {
        width: 'auto',
        height: 'auto',
    },
    fullList: {
        width: 'auto',
        height: 'auto',
    },
    fab: {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
    }
}));

export default function CrsSignatureDrawer(props) {

    const classes = useStyles();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleSetOpenDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(isOpen);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    }

    return (
        <div className={classes.root}>
            <Fab color="secondary" onClick={handleSetOpenDrawer(true)} aria-label="edit" className={classes.fab} disabled={!props.isDocumentRead}>
                <FontAwesomeIcon icon={faSignature} />
            </Fab>
            <Drawer anchor="bottom" variant="persistent" open={isDrawerOpen} onClose={handleSetOpenDrawer(false)}>
                <CrsSignaturePanel
                    documentId={props.documentId}
                    signerDocument={props.signerDocument}
                    isDocumentRead={props.isDocumentRead}
                    pdfContainsInitials={props.pdfContainsInitials}
                    signedPdf={props.signedPdf}
                    onApplySignature={props.onApplySignature}
                    onClearSignedPdf={props.onClearSignedPdf}
                    setCloseDrawer={handleCloseDrawer}
                />
            </Drawer>
        </div>
    );
}