import React from 'react';
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { showModalDialog, closeModalDialog } from '../redux/actions';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        zIndex: 1,
    },
    center_content: {
        justifyContent: 'center'
    },
    line_break: {
        whiteSpace: 'pre-wrap'
    }
});

const CrsModalDialog = (props) => {
    const dispatch = useDispatch();
    const modalDialogState = useSelector(state => state.modalDialog, shallowEqual);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const [acceptButtonLabel, setAcceptButtonLabel] = useState(null);
    const [rejectButtonLabel, setRejectButtonLabel] = useState(null);
    const [source, setSource] = useState('');

    useEffect(() => {
        if (modalDialogState.modalDialogInput) {
            if (modalDialogState.modalDialogInput.title) {
                setTitle(modalDialogState.modalDialogInput.title);
            } else {
                setTitle('');
                console.warn('CrsModalDialog: Title not provided.');
            }
            if (modalDialogState.modalDialogInput.content) {
                setContent(modalDialogState.modalDialogInput.content);
            } else {
                setContent(state => state.content);
                console.warn('CrsModalDialog: Content not provided');
            }
            if (modalDialogState.modalDialogInput.acceptButtonLabel) {
                setAcceptButtonLabel(modalDialogState.modalDialogInput.acceptButtonLabel);
            } else {
                setAcceptButtonLabel('Accept');
            }
            if (modalDialogState.modalDialogInput.rejectButtonLabel) {
                setRejectButtonLabel(modalDialogState.modalDialogInput.rejectButtonLabel);
            } else {
                setRejectButtonLabel('');
            }
            if (modalDialogState.modalDialogInput.source) {
                setSource(modalDialogState.modalDialogInput.source);
            } else {
                setSource('');
            }
            setOpen(true);
        }
    }, [modalDialogState.modalDialogInput]);

    const handleClickButton = (event) => {
        setOpen(false);
        let accepted = event.currentTarget.dataset.accepted === 'true';
        let modalDialogOutput = {
            source: source,
            isAccepted: accepted
        };
        dispatch(showModalDialog(null));
        dispatch(closeModalDialog(modalDialogOutput));
    };

    return (
        <Dialog
            className={classes.root}
            open={open}
            aria-labelledby="crs-modal-dialog-title"
            aria-describedby="crs-modal-dialog-description"
            disableBackdropClick={false}
            disableEscapeKeyDown={true}
        >
            <DialogTitle id="crs-modal-dialog-title">{title}</DialogTitle>
            <DialogContent className={classes.line_break}>
                <DialogContentText id="crs-modal-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.center_content}>
                <Button data-accepted={true} onClick={handleClickButton} variant="contained" color="primary">
                    {acceptButtonLabel}
                </Button>
                {rejectButtonLabel ?
                    <Button data-accepted={false} onClick={handleClickButton} variant="contained" color="primary">
                        {rejectButtonLabel}
                    </Button> : null
                }
            </DialogActions>
        </Dialog>
    );
};

export default CrsModalDialog;