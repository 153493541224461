import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import styles from './CrsMainPage.module.scss';
import crslogo from '../assets/images/CRS_Logo.svg';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
});

const CrsAppBar = (props) => {
    const drawerWidth = props.drawerWidth;
    const useStyles = makeStyles((theme) => ({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            height: '70px'
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            display: 'none',
            marginRight: 26,
        },
        hide: {
            display: 'none',
        },
    }));
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <ThemeProvider theme={theme}>
                <Toolbar className={styles['toolbar']}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap>

                    </Typography>
                    <img src={crslogo} className={styles['image']} alt={'Crop Risk Services Logo'} />
                </Toolbar>
            </ThemeProvider>
        </AppBar>
    );
}

export default CrsAppBar;