import React, { Component } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import styles from './App.scss';
import CrsMainPage from './components/CrsMainPage';

const theme = createMuiTheme({
    palette: {
        primary: {
            //light: will be calculated from palette.primary.main,
            main: '#009CDE',
            text: '#000000',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#002554',
            main: '#002554',
            // dark: will be calculated from palette.secondary.main,
            //contrastText: '#000000',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ThemeProvider theme={theme}>
                <CrsMainPage styles={styles} />
            </ThemeProvider>
        );
    }
}
