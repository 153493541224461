import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CrsBodyDrawer from './CrsBodyDrawer';
import CrsMainGrid from './CrsMainGrid';
import CrsModalDialog from './CrsModalDialog';
import CrsGetSignerDocument from './CrsGetSignerDocument';
import CrsAuthentication from './CrsAuthentication';
import { TermsAndConditions } from '../generalConfiguration';
import CrsAcknowledgement from './CrsAcknowledgement';
import CrsRejectNotice from './CrsRejectNotice';
import CrsDocumentPreviouslySubmitted from './CrsDocumentPreviouslySubmitted';
import CrsDocumentSignatureRejected from './CrsDocumentSignatureRejected';
import CrsDocumentCanceled from './CrsDocumentCanceled'
import CrsDocumentExpired from './CrsDocumentExpired'
const CrsBody = (props) => {
    const drawerWidth = props.drawerWidth;
    const show = typeof props.show === "boolean" ? props.show : true;
    const useStyles = makeStyles((theme) => ({
        toolbar: {
            overflowX: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: '0px',
            marginLeft: '0px',

        },
    }));
    const classes = useStyles();

    const [showAcknowledgement, setShowAcknowledgement] = React.useState(true);
    const [isAckAccepted, setAckAccepted] = useState(false);
    const [isAckRejected, setAckRejected] = useState(false);

    const [signerDocument, setSignerDocument] = useState(null);
    const [isDocumentRead, setIsDocumentRead] = useState(false);
    const [pdfContainsInitials, setPdfContainsInitials] = useState(false);
    const [signatureData, setSignatureData] = useState(null);
    const [signedPdf, setSignedPdf] = useState(null);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    const handleOnGetSignerDocument = (gettedSignerDocument) => {
        setSignerDocument(gettedSignerDocument);
        setIsUserAuthenticated(!gettedSignerDocument.requires2fa);
    };

    const handleOnUserScrollPdf = (pdfData) => {
        if (isDocumentRead === false) {
            setIsDocumentRead(true);
            if (pdfData.includesInitials) {
                setPdfContainsInitials(true);
            }
        }
    };

    const handleOnApplySignature = (signatureData) => {
        setSignatureData(signatureData);
    }

    const handleOnCreateSignedPdf = (pdfBase64) => {
        setSignatureData(null);
        setSignedPdf(pdfBase64);
    };

    const handleOnClearSignedPdf = () => {
        setSignatureData(null);
        setSignedPdf(null);
    };

    const handleOnUserAuthenticated = (authenticated) => {
        setIsUserAuthenticated(authenticated);
    };

    if (show) {
        return (
            <>
                <CrsBodyDrawer show={show} drawerWidth={drawerWidth} toolbarStyle={classes.toolbar} />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <CrsModalDialog />
                    <CrsAcknowledgement
                        show={showAcknowledgement}
                        title={TermsAndConditions.title}
                        content={TermsAndConditions.content}
                        acceptButtonLabel={TermsAndConditions.acceptButtonLabel}
                        rejectButtonLabel={TermsAndConditions.rejectButtonLabel}
                        showRejectNotice={isAckRejected}
                        onAccept={() => {
                            setShowAcknowledgement(false);
                            setAckRejected(false);
                            setAckAccepted(true);
                        }}
                        onReject={() => {
                            setShowAcknowledgement(true);
                            setAckAccepted(false);
                            setAckRejected(true);
                        }}
                    />
                    <CrsRejectNotice
                        show={isAckRejected}
                        title={TermsAndConditions.rejectNoticeTitle}
                        content={TermsAndConditions.rejectNoticeContent}
                        closeButtonLabel={TermsAndConditions.closeRejectNoticeButtonLabel}
                        onClose={() => {
                            setShowAcknowledgement(true);
                            setAckAccepted(false);
                            setAckRejected(false);
                        }}
                    />
                    <CrsGetSignerDocument
                        execute={isAckAccepted}
                        documentId={props.documentId}
                        onGetSignerDocument={handleOnGetSignerDocument}
                    />
                    <CrsAuthentication
                        show={signerDocument !== null && signerDocument.requires2fa === true && signerDocument.signatureComplete === false && signerDocument.requestStatus !== 3 && signerDocument.requestStatus !== 5 && signerDocument.requestStatus !== 4}
                        signerDocument={signerDocument}
                        onUserAuthenticated={handleOnUserAuthenticated}
                    />
                    <CrsMainGrid
                        show={isUserAuthenticated === true && signerDocument !== null && signerDocument.signatureComplete === false && signerDocument.requestStatus !== 3 && signerDocument.requestStatus !== 5 && signerDocument.requestStatus !== 4}
                        documentId={props.documentId}
                        signerDocument={signerDocument}
                        isDocumentRead={isDocumentRead}
                        pdfContainsInitials={pdfContainsInitials}
                        signatureData={signatureData}
                        signedPdf={signedPdf}
                        onUserScrollPdf={handleOnUserScrollPdf}
                        onApplySignature={handleOnApplySignature}
                        onCreateSignedPdf={handleOnCreateSignedPdf}
                        onClearSignedPdf={handleOnClearSignedPdf}
                    /> 
                    <CrsDocumentPreviouslySubmitted
                        show={signerDocument !== null && signerDocument.signatureComplete === true}
                    />
                    <CrsDocumentSignatureRejected
                        show={signerDocument !== null && signerDocument.requestStatus === 3}
                    />
                    <CrsDocumentCanceled
                        show={signerDocument !== null && signerDocument.requestStatus === 5}
                    />
                    <CrsDocumentExpired
                        show={signerDocument !== null && signerDocument.requestStatus === 4}
                    />
                    </main>
            </>
        );
    } else {
        return null;
    }
}

export default CrsBody;