const getGeolocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator && navigator.geolocation && navigator.geolocation.getCurrentPosition) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    let geoLocation = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        location: `${position.coords.latitude}, ${position.coords.longitude}`
                    };
                    resolve(geoLocation);
                },
                err => {
                    reject(err);
                });
        } else {
            reject('getGeolocation: browser does not support getGeolocationnavigator.geolocation.getCurrentPosition');
        }
    });
};

export default getGeolocation;