import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import CrsBody from './CrsBody';
import CrsInvalidUrl from './CrsInvalidUrl';
import CrsAppBar from './CrsAppBar';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    }
}));

const CrsMainPage = () => {
    const classes = useStyles();
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

    return (
        <div className={classes.root}>
            <CssBaseline />
            <CrsAppBar drawerWidth={drawerWidth} />
            <Router basename={baseUrl}>
                <Switch>
                    <Route exact path="/docs/id/:documentId" render={props =>
                        (
                            <>
                                <CrsBody                                    
                                    drawerWidth={drawerWidth}
                                    documentId={props.match.params.documentId}
                                />
                            </>
                        )} />
                    <Route path="*" component={CrsInvalidUrl} />
                </Switch>
            </Router>
        </div >
    );
}

export default CrsMainPage;