import { Urls } from '../generalConfiguration';
import axios from 'axios';

const getSignerDocument = (documentId) => {
    return new Promise((resolve, reject) => {
        if (documentId) {
            let getDocumentUrl = `${Urls.GetDocument}/${documentId}`;
            axios.get(
                getDocumentUrl,
                { headers: { Accept: 'application/json' } })
                .then(response => {
                    if (response && response.status >= 200 && response.status < 300 && response.data) {
                        try {
                            let parsedResponse = JSON.parse(response.data);
                            resolve(parsedResponse);
                        } catch (err) {
                            reject(err);
                        }
                    }
                })
                .catch(err => reject(err));
        } else {
            reject(`getSignerDocument: documentId not provided.`);
        }
    });
}

const getQuestions = (personId, signerType) => {
    return new Promise((resolve, reject) => {
        if (typeof personId === 'number' && typeof signerType === 'number') {
            let getQuestionstUrl = `${Urls.GetQuestions}/${personId}/${signerType}`;
            axios.get(
                getQuestionstUrl,
                { headers: { Accept: 'application/json' } })
                .then(response => {
                    if (response && response.status >= 200 && response.status < 300 && response.data) {
                        try {
                            let parsedResponse = JSON.parse(response.data);
                            resolve(parsedResponse);
                        } catch (err) {
                            reject(err);
                        }
                    }
                })
                .catch(err => reject(err));
        } else {
            reject(`getQuestions: personId or signerType not provided.`);
        }
    });
}

const verifyAnswer = (personId, signerType, questionId, answer) => {
    return new Promise((resolve, reject) => {
        if (typeof personId === 'number' && typeof signerType === 'number'
            && typeof questionId === 'number' && answer) {
            let verifyAnswerUrl = Urls.VerifyAnswer;
            let twoFactorAnswer = {
                personId,
                signerType,
                questionId,
                answer
            }
            axios.post(verifyAnswerUrl,
                twoFactorAnswer,
                { headers: { Accept: 'application/json' } })
                .then(response => {
                    if (response && response.status >= 200 && response.status < 300 && response.data) {
                        try {
                            let parsedResponse = JSON.parse(response.data);
                            resolve(parsedResponse);
                        } catch (err) {
                            reject(err);
                        }
                    } else {
                        reject(response);
                    }
                })
                .catch(err => reject(err));
        } else {
            reject(`verifyAnswer: Input parameters are not valid.`);
        }
    });
};

const sendToken = (personId) => {
    return new Promise((resolve, reject) => {
        if (typeof personId === 'number') {
            let getQuestionstUrl = `${Urls.SendToken}/${personId}`;
            axios.get(
                getQuestionstUrl,
                { headers: { Accept: 'application/json' } })
                .then(response => {
                    if (response && response.status >= 200 && response.status < 300 && response.data) {
                        try {
                            let parsedResponse = JSON.parse(response.data);
                            resolve(parsedResponse);
                        } catch (err) {
                            reject(err);
                        }
                    }
                })
                .catch(err => reject(err));
        } else {
            reject(`sendToken: personId not provided.`);
        }
    });
}

const verifyToken = (personId, token) => {
    return new Promise((resolve, reject) => {
        if (typeof personId === 'number' && token) {
            let verifyTokenUrl = `${Urls.VerifyToken}/${personId}/${token}`;
            axios.get(
                verifyTokenUrl,
                { headers: { Accept: 'application/json' } })
                .then(response => {
                    if (response && response.status >= 200 && response.status < 300 && response.data) {
                        try {
                            let parsedResponse = JSON.parse(response.data);
                            resolve(parsedResponse);
                        } catch (err) {
                            reject(err);
                        }
                    }
                })
                .catch(err => reject(err));
        } else {
            reject(`verifyToken: personId or token not provided.`);
        }
    });
}

const getCurrentDateTime = () => {
    return new Promise((resolve, reject) => {
        axios.get(Urls.CurrentDate, { headers: { Accept: 'application/json' } })
            .then(response => {
                if (response && response.status >= 200 && response.status < 300 && response.data) {
                    try {
                        let currentDateTime = new Date(response.data.currentDateTime);
                        resolve(currentDateTime);
                    } catch (err) {
                        reject(err);
                    }
                }
            })
            .catch(err => reject(err));
    });
};

const sendSignedDocument = (documentId, signerDocument) => {
    return new Promise((resolve, reject) => {
        if (documentId) {
            let getDocumentUrl = `${Urls.GetDocument}/${documentId}`;
            axios.put(getDocumentUrl, signerDocument, { headers: { Accept: 'application/json' } })
                .then(response => {
                    if (response && response.status >= 200 && response.status < 300 && response.data) {
                        try {
                            resolve(response.data);
                        } catch (err) {
                            reject(err);
                        }
                    } else {
                        reject(response);
                    }
                })
                .catch(err => reject(err));
        } else {
            reject(`sendSignedDocument: documentId not provided.`);
        }
    });
};

const sendTelemetry = (telemetryData) => {
    return new Promise((resolve, reject) => {
        if (telemetryData) {
            axios.post(Urls.Telemetry, telemetryData, { headers: { Accept: 'application/json' } })
                .then(response => {
                    if (response && response.status >= 200 && response.status < 300 && response.data) {
                        try {
                            resolve(response.data);
                        } catch (err) {
                            reject(err);
                        }
                    } else {
                        reject(response);
                    }
                })
                .catch(err => reject(err));
        } else {
            reject(`sendTelemetry: telemetryData not provided.`);
        }
    });
};

export {
    getSignerDocument,
    getQuestions,
    verifyAnswer,
    sendToken,
    verifyToken,
    getCurrentDateTime,
    sendSignedDocument,
    sendTelemetry
};
